<template>
  <div ref="scrollButton" @click="scrollTop" :class="[{inactive: !scrollButtonVisible}, 'scroll-top']">Наверх</div>
</template>

<script>
export default {
  name: "scrollTop",
  data() {
    return {
      scrollButtonVisible: false,
    }
  },
  created() {
    window.addEventListener("scroll", this.throttle(this.displayScrollButton, 128));
  },
  destroyed() {
    window.removeEventListener("scroll", this.throttle(this.displayScrollButton, 128));
  },
  methods: {
    displayScrollButton() {
      this.scrollButtonVisible = window.scrollY >= window.innerHeight;
    },
    scrollTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    throttle(func, ms) {
      let isThrottled = false,
          savedArgs,
          savedThis;

      function wrapper() {
        if (isThrottled) {
          savedArgs = arguments;
          savedThis = this;
          return;
        }
        func.apply(this, arguments);
        isThrottled = true;
        setTimeout(function () {
          isThrottled = false;
          if (savedArgs) {
            wrapper.apply(savedThis, savedArgs);
            savedArgs = savedThis = null;
          }
        }, ms);
      }

      return wrapper;
    },
  }
}
</script>

<style scoped>

</style>