<template>
  <div class="sgl-ratings">
    <section class="content-section hero-banner-section">
      <div class="inner-container">
        <div class="event-banner">
          <img class="event-banner--bg" :src="require('@/img/rating-hero.png')" alt="#">
          <div class="event-banner--content">
            <div class="event-banner--title">Рейтинг</div>
          </div>
        </div>
      </div>
    </section>
    <section class="content-section">
      <div class="inner-container">
        <div class="sorting-panel sorting-panel__with-icons sorting-panel__with-searchbar">
          <div class="sgl-input">
            <svg class="sgl-input__magnifier" width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                  stroke="#B0B0B0" stroke-linejoin="round"/>
              <path d="M13.9996 13.9996L11.0996 11.0996" stroke="#B0B0B0" stroke-linejoin="round"/>
            </svg>
            <label>
              <input type="text" placeholder="Поиск">
            </label>
            <svg class="sgl-input__cross" width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 0.5L8 8M8 8L15.5 0.5M8 8L0.5 15.5M8 8L15.5 15.5" stroke="white" stroke-linejoin="round"/>
            </svg>
          </div>
          <ul class="icon-tabs-list">
            <!--у элементов есть класс active, для подчеркивания-->
            <li class="all">Все</li>
            <li><img :src="require('@/img/disciplines-logo/counter-strike.png')" alt="#"></li>
            <li><img :src="require('@/img/disciplines-logo/fifa.png')" alt="#"></li>
            <li><img :src="require('@/img/disciplines-logo/nba.png')" alt="#"></li>
            <li><img :src="require('@/img/disciplines-logo/ufc.png')" alt="#"></li>
          </ul>
        </div>
        <div class="disciplines-grid">
          <div v-for="g in 6" :key="g" class="discipline-block">
            <div class="discipline-block--banner">
              <img class="bg" :src="require('@/img/demo-tourament-image.png')" alt="#">
              <img class="logo" :src="require('@/img/cs-go-logo.png')" alt="#">
            </div>
            <media :query="{minWidth:751}">
              <div>
                <router-link to="/" class="sgl-player-panel" v-for="k in 10" :key="k">
                  <div class="place">{{ k }}.</div>
                  <img class="avatar" :src="require('@/img/demo-ava.jpeg')" alt="#">
                  <div class="info">
                    <div class="name">n0tail</div>
                    <div class="nickname">Johan Sundstein</div>
                  </div>
                  <div class="exp">123 321 exp</div>
                </router-link>
              </div>
            </media>
            <media :query="{maxWidth:750}">
              <div>
                <router-link to="/" class="sgl-player-panel" v-for="f in 5" :key="f">
                  <div class="place">{{ f }}.</div>
                  <img class="avatar" :src="require('@/img/demo-ava.jpeg')" alt="#">
                  <div class="info">
                    <div class="name">n0tail</div>
                    <div class="nickname">Johan Sundstein</div>
                  </div>
                  <div class="exp">123 321 exp</div>
                </router-link>
              </div>
            </media>
            <div class="sgl-button sgl-button--grey sgl-button--w100p">Показать еще</div>
          </div>
        </div>
      </div>
    </section>
    <scroll-top/>
  </div>
</template>

<script>
import ScrollTop from "@/components/UIElements/scrollTop";
import Media from 'vue-media'

export default {
  name: "sglRatings",
  components: {ScrollTop, Media},
}
</script>

<style scoped>

</style>